import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Input, Button, Modal, message, Select, Empty, notification } from "antd";
import { MinusOutlined, PlusOutlined, CaretDownOutlined } from "@ant-design/icons";
import { Scrollbars } from "react-custom-scrollbars";
import { useHistory } from "react-router-dom";
import { serverUrl, CWCoreServicesUrl } from "../../constants/config";
import Axios from "axios";
import _ from "lodash";
import { clock } from "../../utility/clock";
// import logoIcon from "../../assets/images/cwpos-blue.png";
import MenuIcon from "../../assets/images/menu.png";
import POSLogo from "../../assets/images/cwPosLogo.png";
import DefaultProductImage from "../../assets/images/default-product.webp";
import "../style.css";
import db from "../../database";
import data from "../../constants/purchaseGrn.json";
import BarCodeComponent from "../../lib/BarCodeComponet";

const { Option } = Select;

const STReceipt = () => {
  const dynamicHeight = 0.5625;
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  const history = useHistory();

  // CLOCK BLOCK START
  const [displayClock, setDisplayClock] = useState(clock());
  useEffect(() => {
    const timerId = setInterval(() => setDisplayClock(clock()), 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, []);
  // CLOCK BLOCK END

  const handlePosLogo = () => {
    history.push("/dashboard");
  };

  const setDefaultImage = (e) => {
    e.target.src = DefaultProductImage;
  };

  const [stDocList, setStDocList] = useState([]);
  const [filteredData, setFilteredData] = useState({stIssueDocNo: "", skuInput: ""})

  useEffect(() => {
    const stDocInput = {
      query: `query {
        comboFill(tableName:"m_transferreceipt", pkName:"m_transferreceipt_id",
     identifier:"m_transferreceipt_id, m_transferissue_id, (select documentno from m_transferissue where m_transferissue_id = m_transferreceipt.m_transferissue_id) as issueno, docstatus"
    whereClause: "coalesce(docstatus, 'DR') = 'DR' and cs_bunit_id = '${tillData.tillAccess.csBunit.csBunitId}'")
    }`,
    };
    Axios({
      url: CWCoreServicesUrl,
      method: "POST",
      data: stDocInput,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    }).then((response) => {
      const { comboFill } = response.data.data;
      let newComboFill = JSON.parse(comboFill);
      setStDocList([...newComboFill]);
    });
  }, []);

  const stDocumentListArray = stDocList.map((stDocObj) =>
      <Option key={stDocObj.m_transferreceipt_id} value={stDocObj.m_transferreceipt_id}>
        {stDocObj.issueno || ""}
      </Option>
  );

  const [stDocProductList, setStDocProductList] = useState([]);

  // useEffect(() => {
  //   if(window.localStorage.getItem('filterSTIssueData')) {
  //     let filterobj = JSON.parse(window.localStorage.getItem('filterSTIssueData'))
  //     setFilteredData(filterobj?.filteredData || {});
  //     setStDocProductList(filterobj?.filterList || []);
  //   }
  // }, []);

  // useEffect(() => {
  //   window.localStorage.setItem('filterSTIssueData', JSON.stringify({
  //     filteredData: filteredData,
  //     filterList: stDocProductList
  //   }));
  // }, [filteredData, stDocProductList]);

  const onSelect = (value) => {
    setFilteredData({...filteredData, stIssueDocNo: value});
    const stIssueDocProductsInput = {
      query: `query {getTabData(tabData:{ad_tab_id:"C744A90D6F654F7EAEEF34B6FD6A5260",
      startRow:"0",
      endRow:"99",
      parentTabId:"3E14F3E26D9D4AC8812D75779315D741",
      parentRecordID:"${value}"})
      {startRow,
      endRow,
      tableName,
      totalRows,
      records,
      messageCode,
      message}}`,
    };
    Axios({
      url: CWCoreServicesUrl,
      method: "POST",
      data: stIssueDocProductsInput,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    }).then((response) => {
      let { records } = response.data.data.getTabData;
      let newRecords = JSON.parse(records);
      let recordsData = newRecords.data.map((item, index) => {
        return {
          ...item,
          issuedQty : item["5509551F9A19437182D40CAD9754BDF7"]
        }
      })
      setStDocProductList([...recordsData]);
    });
  };

  const onFieldButton = (fieldIndex, action) => {
    let newSupplierProductList = [...stDocProductList];
    if (action === "plus") {
      if (newSupplierProductList[fieldIndex].issuedQty <= newSupplierProductList[fieldIndex]["5509551F9A19437182D40CAD9754BDF7"]) {
        newSupplierProductList[fieldIndex]["5509551F9A19437182D40CAD9754BDF7"] = newSupplierProductList[fieldIndex]["5509551F9A19437182D40CAD9754BDF7"];
      } else {
        newSupplierProductList[fieldIndex]["5509551F9A19437182D40CAD9754BDF7"] += 1;
      }
    } else if (action === "minus" && newSupplierProductList[fieldIndex]["5509551F9A19437182D40CAD9754BDF7"] > 0) {
      newSupplierProductList[fieldIndex]["5509551F9A19437182D40CAD9754BDF7"] -= 1;
    } else if (action !== "plus" && action !== "minus") {
      if (newSupplierProductList[fieldIndex].issuedQty <= newSupplierProductList[fieldIndex]["5509551F9A19437182D40CAD9754BDF7"]) {
        newSupplierProductList[fieldIndex]["5509551F9A19437182D40CAD9754BDF7"] = newSupplierProductList[fieldIndex]["5509551F9A19437182D40CAD9754BDF7"];
      } else {
        newSupplierProductList[fieldIndex]["5509551F9A19437182D40CAD9754BDF7"] = Number(action);
      }
    }
    setStDocProductList([...newSupplierProductList]);
  };

  const handleSku = (event) => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      const skuVal = event.target.value;
      const index = stDocProductList.findIndex((element) => {
        return element.C3B426F6C063462BAB008A2E7A5D955F === skuVal || element.C3B426F6C063462BAB008A2E7A5D955F_iden === skuVal;
      });
      if (index !== -1) {
        if (stDocProductList[index].issuedQty <= stDocProductList[index]["5509551F9A19437182D40CAD9754BDF7"]) {
          stDocProductList[index]["5509551F9A19437182D40CAD9754BDF7"] = stDocProductList[index]["5509551F9A19437182D40CAD9754BDF7"];
        } else {
          stDocProductList[index]["5509551F9A19437182D40CAD9754BDF7"] += 1;
        }
        setStDocProductList([...stDocProductList]);
      } else {
        message.error("Product not found");
      }
    }
  };

  const key = `open${Date.now()}`;
  const btn = (
    <Button className="notificationCnfmBtn" onClick={() => notification.close(key)}>
      Confirm
    </Button>
  );

  const handleReceive = () => {
    const formatYmd = (date) => date.toISOString().slice(0, 10);
    const newDate = formatYmd(new Date());
    let mTransferReceiptLineID = "";
    let mProductID = "";
    let csUomId = "";
    let qtyReceived = "";
    let mStoragebintoID = "";
    const stIssueProductsArray = [];
    stDocProductList.map(
      (item, index) => (
        mTransferReceiptLineID = stDocProductList[index]['C86564B3E45648889920F9D4EA74AD0F'],
        mProductID = stDocProductList[index]['C3B426F6C063462BAB008A2E7A5D955F'],
        csUomId = stDocProductList[index]['ACF47A965B0E4922A43CBC77524062D0'],
        qtyReceived = stDocProductList[index]['5509551F9A19437182D40CAD9754BDF7'] === undefined ? 0 : stDocProductList[index]['5509551F9A19437182D40CAD9754BDF7'],
        mStoragebintoID = stDocProductList[index]['AF1CD10275C54C528B8025A37909CF20']
      )
    );
    stIssueProductsArray.push(
      `{
        mTransferReceiptLineID: "${mTransferReceiptLineID}"
        mProductID: "${mProductID}"
        csUomId: "${csUomId}"
        qtyReceived: "${qtyReceived}"
        mStoragebintoID: "${mStoragebintoID}"
        }`
    );
    const createSTTransferReceipt = {
      query: `mutation {insertSTtransferReceipt(receipt:{
              mTransferreceiptID:"${filteredData.stIssueDocNo}",
              cSBunitID : "${tillData.tillAccess.csBunit.csBunitId}",
              cSClientID:"${tillData.tillAccess.csClientId}",
              createdby : "${tillData.tillAccess.csUserId}"
              csDoctypeId: "${tillData.tillAccess.csBunit.cwrCsDoctypeId}",
               receiptDate: "${newDate}",
               mStoragebinId:"${mStoragebintoID}"
              stockTransferIssue: null,    
              lines:[${stIssueProductsArray}]
          }
          ) {
                 type
                  message
                  mTransferreceiptID
                  documentno
                  cSClientID
                  cSBunitID
                  csDoctypeId
                  receiptDate
                  mStoragebinId
                  stockTransferIssue
             }
          }	`,
    }
    Axios({
      url: serverUrl,
      method: "POST",
      data: createSTTransferReceipt,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    }).then((response) => {
      if (response.status === 200) {
        let mTransferreceiptID = response.data.data.insertSTtransferReceipt.mTransferreceiptID    
        const processSTReceipt = {
          query: `mutation {processStransferReceipt(process:{
              cSClientID:"${tillData.tillAccess.csClientId}",
                createdby : "${tillData.tillAccess.csUserId}",
              cSBunitID : "${tillData.tillAccess.csBunit.csBunitId}",
                mTransferreceiptID:"${mTransferreceiptID}"
            
            }) {
                     message
                     documentNo
               }
            }`,
        }
        Axios({
          url: serverUrl,
          method: 'POST',
          data: processSTReceipt,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${tokens.token_type} ${tokens.access_token}`,
          },
        }).then((response) => {
          // message.success(response.data.data.processStransferReceipt.message);
          notification.open({
            message: response.data.data.processStransferReceipt.message,
            type: "success",
            btn,
            key,
            duration: 15,
            style: {
              width: 500
            }
          });
          setFilteredData([]);
          setStDocProductList([]);
        });
      }
    })
  };

  return (
    <div>
      <BarCodeComponent onScan={handleSku} />
      <div style={data.purchaseGrn.header}>
        <Row style={{ height: `${3.7 * dynamicHeight}vw` }}>
          <Col span={2}>
            <img src={MenuIcon} style={data.purchaseGrn.header.menuIcon} alt="" />
          </Col>
          <Col span={10}>
            <label style={data.purchaseGrn.header.posHeadLabel}>{tillData.tillAccess.csBunit.name}</label>
          </Col>
          <Col span={8}>
            <label style={data.purchaseGrn.header.tillName}>{tillData.tillAccess.cwrTill.till}</label>
          </Col>
          <Col span={2} style={data.purchaseGrn.header.right}>
            <label style={data.purchaseGrn.header.time}>{displayClock}</label>
          </Col>
          <Col span={2} style={data.purchaseGrn.header.right}>
            <img onClick={handlePosLogo} src={POSLogo} style={data.purchaseGrn.header.logoIcon} alt="" />
          </Col>
        </Row>
      </div>
      <div style={{ padding: 10 }}>
        <p style={data.purchaseGrn.purchaseText}>Stock Receipt</p>
        <Row style={{ paddingBottom: 10 }}>
          <Col span={14}>
            <Row>
              <Col span={12}>
                <Row>
                  <Col span={9} style={{ alignSelf: "center" }}>
                    <p style={data.purchaseGrn.inputLabel}>ST Issue Document</p>
                  </Col>
                  <Col span={15}>
                    <Select
                      // suffixIcon={<CaretDownOutlined />}
                      placeholder="Select"
                      onChange={(e) => onSelect(e)}
                      value={filteredData.stIssueDocNo}
                      style={data.purchaseGrn.supplierSelect}
                      showSearch
                      // allowClear
                      optionFilterProp="children"
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      className="supplierSelect"
                    >
                      {stDocumentListArray}
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col span={10} style={{ paddingLeft: 10 }}>
                <Row>
                  <Col span={7} style={{ alignSelf: "center" }}>
                    <p style={data.purchaseGrn.inputLabel}>SKU/Name</p>
                  </Col>
                  <Col span={17}>
                    <Input
                      style={data.purchaseGrn.skuInput}
                      className="skuInput"
                      allowClear
                      value={filteredData.skuInput}
                      onChange={(e) => setFilteredData({...filteredData, skuInput: e.target.value})}
                      onKeyPress={handleSku}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={10} />
        </Row>
        <Card key="z" style={data.purchaseGrn.mainCardFirst} bodyStyle={{ padding: 0 }}>
          <Row style={data.purchaseGrn.headingRow}>
            <Col span={10}>
              <strong>Product</strong>
            </Col>
            <Col span={8} style={data.purchaseGrn.colAlign}>
              <strong>Issued Qty</strong>
            </Col>
            <Col span={6} style={data.purchaseGrn.colAlign}>
              <strong>Receiving Qty</strong>
            </Col>
          </Row>
          {stDocProductList.length > 0 ? (
            <div>
              <Scrollbars autoHide className="purchaseGrnScroll">
                {stDocProductList.map((item, index) => (
                  <Row style={{ padding: "10px 10px 0px" }}>
                    <Col span={10}>
                      <Row>
                        <Col span={4}>
                          <img onError={setDefaultImage} src={item.img} alt="" style={data.purchaseGrn.prodImgInTable} />
                        </Col>
                        <Col span={20} style={{ paddingTop: 5 }}>
                          <Row>
                            <p style={data.purchaseGrn.desc}>{item.C3B426F6C063462BAB008A2E7A5D955F}</p>
                          </Row>
                          <Row>
                            <p style={data.purchaseGrn.prodTitle}>{item.C3B426F6C063462BAB008A2E7A5D955F_iden}</p>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={8} style={{ textAlign: "center", alignSelf: "center" }}>
                      <Input readOnly={true} style={data.purchaseGrn.priceTotalAmount} value={item.issuedQty} />
                    </Col>
                    <Col span={6} style={{ textAlign: "center", alignSelf: "center" }}>
                      <div style={data.purchaseGrn.qtyInputsDiv}>
                        <MinusOutlined style={data.purchaseGrn.decrementInpt} type="minus" onClick={() => onFieldButton(index, "minus")} />
                        <Form.Item style={data.purchaseGrn.qtyInputFormitem}>
                          <Input
                            type="number"
                            value={item["5509551F9A19437182D40CAD9754BDF7"]}
                            style={data.purchaseGrn.qtyInput}
                            onChange={(e) => {
                              onFieldButton(index, e.target.value);
                            }}
                          />
                        </Form.Item>
                        <PlusOutlined style={data.purchaseGrn.incrementInpt} type="plus" onClick={() => onFieldButton(index, "plus")} />
                      </div>
                    </Col>
                  </Row>
                ))}
              </Scrollbars>
            </div>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ margin: "10% 0px" }} />
          )}
          <div style={{ display: stDocProductList.length > 0 ? "block" : "none" }}>
            <Row style={{ padding: 10 }}>
              <Col span={10}>
                <p style={data.purchaseGrn.totalProducts}>Total Products: {_.filter(stDocProductList, item => item["5509551F9A19437182D40CAD9754BDF7"] > 0)?.length || 0}</p>
              </Col>
              <Col span={8}>
                <p style={data.purchaseGrn.totalQty}>Total IssuedQty: {_.sumBy(stDocProductList, "issuedQty")} </p>
              </Col>
              <Col span={6}>
                <p style={data.purchaseGrn.totalValue}>Total ReceivingQty: {_.sumBy(stDocProductList, "5509551F9A19437182D40CAD9754BDF7")}</p>
              </Col>
            </Row>
            <Row style={{ padding: 10 }}>
              <Col span={21} />
              <Col span={3} style={{ float: "right" }}>
                <Button style={data.purchaseGrn.receiveBtn} onClick={handleReceive}>
                  <span style={data.purchaseGrn.receiveBtn.label}>RECEIVE</span>
                </Button>
              </Col>
            </Row>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default STReceipt;
