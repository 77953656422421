import React, { useEffect } from "react";
import { Row, Col, Card, message } from "antd";
import { useAuth } from "../../lib/auth";
import { useHistory } from "react-router-dom";
import { serverUrl } from "../../constants/config";
import { v4 as uuidv4 } from "uuid";
import POS from "../../assets/images/pos.svg";
import PurchaseGRN from "../../assets/images/purchasegrn.svg";
import Delivery from "../../assets/images/delivery.svg";
import CashMgmt from "../../assets/images/cashregister.svg";
import Reports from "../../assets/images/reports.svg";
import Stock from "../../assets/images/stock.svg";
import Settings from "../../assets/images/settings.svg";
import dashboardImg from "../../assets/images/dashboard.svg";
import POSLogo from "../../assets/images/cwPosLogo.png";
import sync from "../../assets/images/sync.svg";
import logoutIcon from "../../assets/images/logout.svg";
import dashboardBG from "../../assets/images/dashboard_newbg.png";
import DayClose from "../../assets/images/day_close.svg";
import Axios from "axios";
import db from "../../database";
import data from "../../constants/dashboard.json";
import "../style.css";

const Dashboard = () => {
  const dynamicHeight = 0.5625;
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  const username = localStorage.getItem("username");
  const tillaccess = JSON.parse(tillData.tillAccess.userAccessController);

  const { setAuthTokens } = useAuth();
  const history = useHistory();

  useEffect(() => {
    const nWStatus = navigator.onLine ? "online" : "offline";
    if (nWStatus === "online") {
      db.tillEvents
        .where("isSynced")
        .equals(0)
        .toArray()
        .then((events) => {
          if (events.length > 0) {
            for (let i = 0; i < events.length; i += 1) {
              const event = events[i];
              const tillCash = [];
              for (let j = 0; j < event.allPaymentsData.length; j += 1) {
                const paymentObj = event.allPaymentsData[j];

                if (paymentObj.name.toLowerCase() === "cash") {
                  const openedCashEvents = [];
                  const closedCashEvents = [];

                  for (let x = 0; x < event.openingCashDenominations.length; x += 1) {
                    openedCashEvents.push(`{
                      cwrCashEventdetailsID:"${uuidv4().replace(/-/g, "").toUpperCase()}"
                      count:${event.openingCashDenominations[x].count}
                      amount:${event.openingCashDenominations[x].amount}
                      denomination:${event.openingCashDenominations[x].denomination}
                    }`);
                  }

                  for (let z = 0; z < event.closingCashDenominations.length; z += 1) {
                    closedCashEvents.push(`{
                      cwrCashEventdetailsID:"${uuidv4().replace(/-/g, "").toUpperCase()}"
                      count:${event.closingCashDenominations[z].count}
                      amount:${event.closingCashDenominations[z].amount}
                      denomination:${event.closingCashDenominations[z].denomination}
                    }`);
                  }

                  tillCash.push(`{
                    cwrTillCashId: "${uuidv4().replace(/-/g, "").toUpperCase()}"
                    date: "${event.closingTime}"
                    finPaymentmethodId: "${paymentObj.finPaymentmethodId}"
                    opening: ${event.openingCash}
                    sales: ${paymentObj.cashSaleAmount}
                    netsales: ${paymentObj.cashSaleAmount}
                    cashin: 0
                    cashout: 0
                    retainAmt: 0
                    closing: ${event.closingCash}
                    returns: 0
                    iscash: 1
                    isclose: 1
                    cashEvents: [
                      {
                        cwrCashEventsID: "${uuidv4().replace(/-/g, "").toUpperCase()}"
                        amount: ${event.openingCash}
                        expected: 0
                        diff: 0
                        transactionCount: 0
                        type: "Open"
                        description: ""
                        cashEventDetails: [${openedCashEvents}]
                      },
                      {
                        cwrCashEventsID: "${uuidv4().replace(/-/g, "").toUpperCase()}"
                        amount: ${event.closingCash}
                        expected: ${paymentObj.expectedAmount}
                        diff: ${paymentObj.difference}
                        transactionCount: ${paymentObj.transactionCount}
                        type: "Close"
                        description: ""
                        cashEventDetails: [${closedCashEvents}]
                      }
                    ]
                  }`);
                } else {
                  tillCash.push(`{
                    cwrTillCashId: "${uuidv4().replace(/-/g, "").toUpperCase()}"
                    date: "${event.closingTime}"
                    finPaymentmethodId: "${paymentObj.finPaymentmethodId}"
                    opening: 0
                    sales: ${paymentObj.expectedAmount}
                    netsales: ${paymentObj.expectedAmount}
                    cashin: 0
                    cashout: 0
                    retainAmt: 0
                    closing: ${parseFloat(paymentObj.amount)}
                    returns: 0
                    iscash: 0
                    isclose: 1
                    cashEvents: [
                      {
                        cwrCashEventsID: "${uuidv4().replace(/-/g, "").toUpperCase()}"
                        amount: ${parseFloat(paymentObj.amount)}
                        expected: ${paymentObj.expectedAmount}
                        diff: ${paymentObj.difference}
                        transactionCount: ${paymentObj.transactionCount}
                        type: "Close"
                        description: ""
                        cashEventDetails: []
                      }
                    ]
                  }`);
                }
              }

              const paramsInput = {
                query: `mutation{
                  upsertTill(tillInfo:{
                    cwrTillID:"${tillData.tillAccess.cwrTill.cwrTillID}"
                    csbunitID:"${tillData.tillAccess.csBunit.csBunitId}"
                    csclientID:"${tillData.tillAccess.csClientId}"
                    created:"${event.openingTime}"
                    createdby:"${tillData.tillAccess.csUserId}"
                    updated:"${event.openingTime}"
                    updatedby:"${tillData.tillAccess.csUserId}"
                    tillCash:[${tillCash}]
                  })
                  {    
                    status
                    message
                    cwrTillID
                          tillCash{
                      
                      cwrTillCashId
                      date
                      finPaymentmethodId
                      opening
                      sales
                      netsales
                      cashin
                      cashout
                      retainAmt
                      closing
                      returns
                      iscash
                      isclose
                      cashEvents{
                        cwrCashEventsID
                        amount
                        expected
                        diff
                        transactionCount
                        type
                        description
                        cashEventDetails{
                          cwrCashEventdetailsID
                          count
                          amount
                          denomination
                        }
                      }
                    }
                  }
                }`,
              };
              Axios({
                url: serverUrl,
                method: "POST",
                data: paramsInput,
                headers: {
                  "Content-Type": "Application/json",
                  Authorization: `${tokens.token_type} ${tokens.access_token}`,
                },
              }).then((response) => {
                const result = response.data.data.upsertTill;
                const status = result.status;
                if (status === "200") {
                  console.log(event.tillSessionId);
                  db.tillEvents.where("tillSessionId").equals(event.tillSessionId).modify({ isSynced: 1 });
                } else {
                  console.log("Failed Till Session Sync ====> ", response);
                  db.tillEvents.where("tillSessionId").equals(event.tillSessionId).modify({ isSynced: 2 });
                }
              });
            }
          }
        });
    }
  }, []);

  const handleLogout = () => {
    setAuthTokens();
    localStorage.removeItem("tokens");
  };

  const syncData = () => {
    history.push("/sync");
  };

  const selectActivity = (activity) => {
    if (activity === "POS") {
      if (tillaccess.pos === "Y") {
        history.push("/open-till");
      } else {
        message.error("Access for POS Denied");
      }
    }
    if (activity === "Cash Management") {
      if (tillaccess.cashManagement === "Y") {
        const tillSession = localStorage.getItem("tillSession");
        if (tillSession) {
          if (JSON.parse(tillSession).tillStatus === "open") {
            const storedParkedList = JSON.parse(localStorage.getItem("parkedList"));
            const initialParkedList = storedParkedList ? storedParkedList : [];
            if (initialParkedList.length === 0) {
              history.push("/close-till");
            } else {
              message.warning("Please close parked bills before closing till");
            }
          } else {
            history.push("/open-till");
          }
        }
      } else {
        message.error("Access for Cash Management Denied");
      }
    }
    if (activity === "Day Close") {
      if (tillaccess.dayEnd === "Y") {
        history.push("/day-close");
      } else {
        message.error("Access for Day Close Denied");
      }
    }
    if (activity === "PurchaseGRN") {
      if (tillaccess.purchase === "Y") {
        history.push("/purchase-grn");
      } else {
        message.error("Access for Purchase GRN Denied");
      }
    }
    if (activity === "ST Receipt") {
      if (tillaccess.stReceipt === "Y") {
        history.push("/stock-receipt");
      } else {
        message.error("Access for Goods Receipt Denied");
      }
    }
    if (activity === "Wastage Qty") {
      if (tillaccess.wastage === "Y") {
        history.push("/wastage-qty");
      } else {
        message.error("Access for Wastage Qty Denied");
      }
    }
    if (activity === "Price Update") {
      if (tillaccess.priceUpdate === "Y") {
        history.push("/price-update");
      } else {
        message.error("Access for Price Update Denied");
      }
    }
    if (activity === "B2B Sales") {
      if (tillaccess.b2BSales === "Y") {
        history.push("/b2b-sales");
      } else {
        message.error("Access for B2B Sales Denied");
      }
    }
  };

  const workType = [
    { workTypeName: "POS", workTypeImg: POS },
    // { workTypeName: "Settings", workTypeImg: Settings },
    // { workTypeName: "Dashboard", workTypeImg: dashboardImg },
    // { workTypeName: "Stock", workTypeImg: Stock },
    { workTypeName: "PurchaseGRN", workTypeImg: PurchaseGRN },
    { workTypeName: "ST Receipt", workTypeImg: Reports },
    { workTypeName: "Wastage Qty", workTypeImg: Stock },
    // { workTypeName: "Delivery", workTypeImg: Delivery },
    { workTypeName: "Day Close", workTypeImg: DayClose },
    { workTypeName: "Cash Management", workTypeImg: CashMgmt },
    { workTypeName: "Price Update", workTypeImg: Reports },
    { workTypeName: "B2B Sales", workTypeImg: PurchaseGRN },
    // { workTypeName: "Reports", workTypeImg: Reports },
  ];

  const sectionStyle = {
    width: data.dashboardStyles.sectionStyle.width,
    height: data.dashboardStyles.sectionStyle.height,
    backgroundImage: `url(${dashboardBG})`,
    backgroundPosition: data.dashboardStyles.sectionStyle.backgroundPosition,
    backgroundSize: data.dashboardStyles.sectionStyle.backgroundSize,
    backgroundRepeat: data.dashboardStyles.sectionStyle.backgroundRepeat,
    position: data.dashboardStyles.sectionStyle.position,
  };

  return (
    <div>
      <div style={data.dashboardStyles.header}>
        <Row style={{ height: `${3.7 * dynamicHeight}vw` }}>
          <Col span={3}>
            <img src={POSLogo} style={data.dashboardStyles.header.logoIcon} alt="" />
          </Col>
          <Col span={6}>
            <label style={data.dashboardStyles.header.headLabel}>{tillData.tillAccess.csBunit.name}</label>
          </Col>
          <Col span={8}>
            <label style={data.dashboardStyles.dashboardInfo}>{tillData.tillAccess.cwrTill.till}</label>
          </Col>
          <Col span={7} style={data.dashboardStyles.right}>
            <label style={data.dashboardStyles.dashboardGreet}>Hi, {username} &emsp;</label>
          </Col>
        </Row>
      </div>
      <div style={sectionStyle}>
        <Row style={{ padding: data.dashboardStyles.padding }} gutter={[24, 24]}>
          {workType &&
            workType.length > 0 &&
            workType.map((item, index) => (
              <Col key={index} xs={{ span: 12 }} lg={{ span: 4 }}>
                <Card bodyStyle={{ padding: 5 }} style={data.dashboardStyles.cardWorkType} onClick={() => selectActivity(item.workTypeName)}>
                  <Row justify="center">
                    <img style={data.dashboardStyles.imgWorkType} src={item.workTypeImg} alt="" />
                  </Row>
                  <Row justify="center">
                    <label style={data.dashboardStyles.dashboardLabel}>{item.workTypeName}</label>
                  </Row>
                </Card>
              </Col>
            ))}
        </Row>
        <Row style={{ padding: data.dashboardStyles.padding }}>
          <Col xs={{ span: 12 }} lg={{ span: 16 }}>
            <img style={data.dashboardStyles.sync} src={sync} alt="" onClick={syncData} />
            <img style={data.dashboardStyles.logout} src={logoutIcon} onClick={handleLogout} alt="" />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Dashboard;
