export const htmlPrint = (printReciptData, receiptType) => {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const printWindow = window.open("", "printWindow", "width=0,height=0");
  let printData = tillData.tillAccess.cwrTill.printTemplate.htmlcode2;

  // console.log(printReciptData);

  const addedToCart = printReciptData.items;
  const taxKeyValue = [];
  const taxType = [];

  for (let i = 0; i < addedToCart.length; i += 1) {
    let taxRate = addedToCart[i].taxRate;
    if (taxType.indexOf(taxRate) !== -1) {
      let taxVal = addedToCart[i].taxAmount / 2;
      for (let j = 0; j < taxKeyValue.length; j += 1) {
        const keys = Object.keys(taxKeyValue[j]);
        if (keys[0].toString() === taxRate.toString()) {
          taxKeyValue[j][taxRate] = taxKeyValue[j][taxRate] + taxVal;
        }
      }
    } else {
      taxType.push(taxRate);
      let taxVal = addedToCart[i].taxAmount / 2;
      taxKeyValue.push({ [taxRate]: taxVal });
    }
  }

  let taxAmountBreakup = "";
  taxKeyValue.map((value, i) => {
    return (taxAmountBreakup += `<tr>
        <td>CGST ${taxType[i] / 2}%</td>
        <td>${value[taxType[i]].toFixed(2)}</td>
      </tr>
      <tr>
        <td>SGST ${taxType[i] / 2}%</td>
        <td>${value[taxType[i]].toFixed(2)}</td>
      </tr>`);
  });

  let printItems = "";
  printReciptData.items.map((data) => {
    return (printItems += `<tr class="left">
        <td colspan="2">${data.shortDescription === null ? data.name : data.shortDescription}</td>
        <td>${parseFloat(data.weight).toFixed(2)}</td>
        <td>${parseFloat(data.salePrice).toFixed(2)}</td>
     </tr>
     <tr class="left">
        <td>${data.hsncode === null ? "-" : data.hsncode}</td>
        <td>${parseFloat(parseFloat(data.taxAmount) / 2).toFixed(2)}</td>
        <td>${parseFloat(parseFloat(data.taxAmount) / 2).toFixed(2)}</td>
        <td>${parseFloat(data.nettotal).toFixed(2)}</td>
     </tr>
     `);
  });

  let printPayments = "";
  printReciptData.payments.map((data) => {
    return (printPayments += `<tr>
           <td>${data.name}</td>
           <td>${parseFloat(data.amount).toFixed(2)}</td>
         </tr>`);
  });

  // tempPrintData start
/*   let printData = `<!DOCTYPE html>
  <html lang="en">
     <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Receipt</title>
        <style>
           * {
             font-size: 12px;
             font-family: "Times New Roman";
           }
           .centered {
             text-align: center;
           }
           .left {
             text-align: left;
           }
           .right {
             text-align: right;
           }
           .center {
             margin-left: auto;
             margin-right: auto;
           }
           .sp-top {
               border-top: 1px solid black;
               border-collapse: collapse;
           }
           .sp-btm {
               border-bottom: 1px solid black;
               border-collapse: collapse;
           }
        </style>
     </head>
     <body>
        <div style="width: 100%;">
           <h1 style="font-size: 30px; font-weight: bold; text-align: center; margin-bottom: 0px;">CW Suite</h1>
           <!-- <img alt="CW ERP" style ="display: block; margin-left: auto; margin-right: auto; height: 50px; width: 100px;" src="https://cw.solutions/wp-content/uploads/2020/02/CW_LOGO_90.jpg"> -->
           <br />
           <p class="centered" style="margin-top: 2px; margin-bottom: 2px;">
              @@csBunitCustomerAddressFulladdress@@
              <br>Phone: @@csBunitCustomerAddressPhone@@
           </p>
           <hr style="border-top: 1px solid #000000;" />
           <p class="centered" style="margin-top: 2px; margin-bottom: 2px;"><b>@@receiptType@@</b></p>
           <table style="width: 100%; margin-left: auto; margin-right: auto;">
              <tr>
                 <td>Customer: @@printReciptDataCustomerName@@</td>
                 <td>Cashier: @@username@@</td>
              </tr>
              <tr>
                 <td>@@printReciptDataCustomerMobileNo@@</td>
                 <td></td>
              </tr>
              <tr>
                 <td>Document: @@printReciptDataDocumentno@@</td>
                 <td>Date: @@date@@</td>
              </tr>
           </table>
           <table style="width: 100%;">
              <thead>
                 <tr class="left">
                    <th class="sp-top" colspan="2">NAME</th>
                    <th class="sp-top">QTY</th>
                    <th class="sp-top">PRICE</th>
                 </tr>
                 <tr class="left">
                  <th class="sp-btm">HSN</th>
                  <th class="sp-btm">CGST</th>
                  <th class="sp-btm">SGST</th>
                  <th class="sp-btm">AMOUNT</th>
                 </tr>
              </thead>
              <tbody>
                 @@printReciptDataItems@@
              </tbody>
           </table>
           <hr style="border-top: 1px solid #000000;" />
           <table style="width: 100%;">
              <tr>
                 <td>Total Items: @@printReciptDataItemsLength@@</td>
                 <td>Total Quantity: @@printReciptDataTotalQty@@</td>
              </tr>
           </table>
           <hr style="border-top: 1px solid #000000;" />
           <table style="width: 100%;">
           @@taxAmountBreakup@@
              <tr>
                 <td>Net Amount:</td>
                 <td>@@netAmount@@</td>
              </tr>
              <tr>
                 <td>Tax Amount:</td>
                 <td>@@taxAmount@@</td>
              </tr>
              <tr>
                 <td>Discount:</td>
                 <td>@@discountAmount@@</td>
              </tr>
              <tr>
                 <td>Round Off:</td>
                 <td>@@roundOff@@</td>
              </tr>
              <tr>
                 <td><b>Total</b></td>
                 <td><b>@@total@@</b></td>
              </tr>
           </table>
           <hr style="border-top: 1px solid #000000;" />
           <table style="width: 100%;">
              @@printReciptDataPayments@@
              <tr>
                 <td>Change</td>
                 <td>@@cashChangeAmount@@</td>
              </tr>
              <tr>
                 <td><b>Amount Received: <b></td>
                 <td><b>@@amountReceived@@</b></td>
              </tr>
           </table>
           <br/>
           <p class="centered">Coupon Code</p>
           <div style="text-align: center;">
           @@couponCode@@
           </div>
           <br/>
           <p class="centered">Thanks for your purchase!</p>
        </div>
       @@additionalBodyData@@
     </body>
  </html>`; */
  // tempPrintData end

  const customerName = printReciptData.customer.name === "Anonymous Customer" ? "" : printReciptData.customer.name;
  const customerMobile = printReciptData.customer.mobileNo === "9999999999" ? "" : printReciptData.customer.mobileNo;
  const fullAddress = tillData.tillAccess.csBunit.locations[0].fulladdress.replaceAll(", ", ",<br />");
  let couponCodeData = "";

  if(printReciptData.voucherCode !== null){
   couponCodeData = `<img src="https://api.qrserver.com/v1/create-qr-code/?data=${printReciptData.voucherCode}&amp;size=100x100" alt="CouponCode" title="" /><p>${printReciptData.voucherCode}</p>`;
  }

  // printData = printData.replaceAll("@@csBunitName@@", `${tillData.tillAccess.csBunit.name}`);
  printData = printData.replaceAll("@@csBunitCustomerAddressFulladdress@@", `${fullAddress}`);
  // printData = printData.replaceAll("@@csBunitCustomerAddressCity@@", `${tillData.tillAccess.csBunit.customerAddress.city}`);
  printData = printData.replaceAll("@@csBunitCustomerAddressPhone@@", `${tillData.tillAccess.csBunit.locations[0].phone}`);
  printData = printData.replaceAll("@@receiptType@@", `${receiptType}`);
  printData = printData.replaceAll("@@username@@", `${localStorage.getItem("username")}`);
  printData = printData.replaceAll("@@printReciptDataDocumentno@@", `${printReciptData.documentno}`);
  printData = printData.replaceAll("@@date@@", `${new Date().toLocaleString()}`);
  printData = printData.replaceAll("@@printReciptDataCustomerName@@", `${customerName}`);
  printData = printData.replaceAll("@@printReciptDataCustomerMobileNo@@", `${customerMobile}`);
  printData = printData.replaceAll("@@printReciptDataItems@@", `${printItems}`);
  printData = printData.replaceAll("@@printReciptDataItemsLength@@", `${parseFloat(printReciptData.items.length).toFixed(2)}`);
  printData = printData.replaceAll("@@printReciptDataTotalQty@@", `${parseFloat(printReciptData.totalQty).toFixed(2)}`);
  printData = printData.replaceAll("@@taxAmountBreakup@@", `${taxAmountBreakup}`);
  printData = printData.replaceAll("@@netAmount@@", `${(parseFloat(printReciptData.total) - parseFloat(printReciptData.tax)).toFixed(2)}`);
  printData = printData.replaceAll("@@taxAmount@@", `${parseFloat(printReciptData.tax).toFixed(2)}`);
  printData = printData.replaceAll("@@discountAmount@@", `${parseFloat(printReciptData.discount).toFixed(2)}`);
  printData = printData.replaceAll("@@roundOff@@", `${parseFloat(printReciptData.roundOff).toFixed(2)}`);
  printData = printData.replaceAll("@@total@@", `${parseFloat(printReciptData.total).toFixed(2)}`);
  printData = printData.replaceAll("@@printReciptDataPayments@@", `${printPayments}`);
  printData = printData.replaceAll("@@amountReceived@@", `${parseFloat(printReciptData.paid).toFixed(2)}`);
  printData = printData.replaceAll("@@cashChangeAmount@@", `${parseFloat(printReciptData.change).toFixed(2)}`);
  printData = printData.replaceAll("@@couponCode@@", `${couponCodeData}`);
  printData = printData.replaceAll("@@additionalBodyData@@", ``);
  
  printWindow.document.write(printData);
  const scriptData = document.createElement('script');
  scriptData.innerHTML = "Promise.all(Array.from(document.images).filter(img => !img.complete).map(img => new Promise(resolve => { img.onload = img.onerror = resolve; }))).then(() => { window.print(); window.close(); })";
  printWindow.document.getElementsByTagName('body')[0].appendChild(scriptData);
  
};