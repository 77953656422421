import React, { useState } from "react";
import { Card, Row, Col, Input, Divider, Table } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import { Scrollbars } from "react-custom-scrollbars";
import Quantity from "../../../assets/images/weigher.png";
import Sales from "../../../assets/images/sales.png";
import Offers from "../../../assets/images/offers.png";
import Stock from "../../../assets/images/stock-white.png";
import Info from "../../../assets/images/Info.png";
import Aclose from "../../../assets/images/close.png";
import ok from "../../../assets/images/tick.svg";
import Backspace from "../../../assets/images/backspac.png";
import DefaultProductImage from "../../../assets/images/default-product.webp";
import "../../style.css";
import AspectRatio from "react-aspect-ratio";
import DiscountImg from "../../../assets/images/discountQty.png";
import StockImg2 from "../../../assets/images/home.png";
import DownArrowGreen from "../../../assets/images/downArrowGreen.svg";
import DownArrowRed from "../../../assets/images/downArrowRed.svg";
import UpArrowGreen from "../../../assets/images/upArrowGreen.svg";
import CircleCloseIcon from "../../../assets/images/circle-close-icon.svg";
import data from "../../../constants/retailPos.json";

const QuantityUpdate = (props) => {
  const { selectedProductInCart, addProduct, setIsQtyUpdate } = props;
  const setDefaultImage = (e) => {
    e.target.src = DefaultProductImage;
  };

  const [weight, setWeight] = useState("");

  const onChangeWeight = (event) => {
    setWeight(event.target.value);
  };

  const handleWeight = (value) => {
    if (weight === "" && value === "x") {
      setWeight("");
    } else if (value === "x") {
      setWeight(`${weight.toString().substring(0, weight.toString().length - 1)}`);
    } else {
      setWeight(`${weight}${value}`);
    }
  };

  const addQuantity = () => {
    const validation = RegExp("^[0-9]+(.[0-9]{1,2})?$");
    if (validation.test(parseFloat(weight))) {
      const netWeight = parseFloat(weight) - parseFloat(selectedProductInCart.weight);
      if(!selectedProductInCart.isReturn){
        addProduct(selectedProductInCart, netWeight);
      }
      setIsQtyUpdate(false);
    } else {
      console.warn("Invalid input", weight);
    }
  };

  const full = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xl: { span: 24 },
    xxl: { span: 24 },
  };

  const four = {
    xs: { span: 4 },
    sm: { span: 4 },
    md: { span: 4 },
    lg: { span: 4 },
    xl: { span: 4 },
    xxl: { span: 4 },
  };

  const half = {
    xs: { span: 12 },
    sm: { span: 12 },
    md: { span: 12 },
    lg: { span: 12 },
    xl: { span: 12 },
    xxl: { span: 12 },
  };

  const twenty = {
    xs: { span: 20 },
    sm: { span: 20 },
    md: { span: 20 },
    lg: { span: 20 },
    xl: { span: 20 },
    xxl: { span: 20 },
  };

  const offersList = [
    {
      title: "Slurrp form millet Dosa Mix",
      offer: "Buy 1 Get 1 Free",
    },
    {
      title: "Royal Tulsi Green Tea...",
      offer: "Buy 1 Get 1 Free",
    },
    {
      title: "BB home floor cleaner",
      offer: "Buy 1 Get 1 Free",
    },
    {
      title: "Timios Snacks - Rings, Tomato",
      offer: "Buy 2 Get 1 Free",
    },
    {
      title: "Again Milk Drink - Strawberry",
      offer: "Buy 2 Get 1 Free",
    },
  ];

  const stockList = [
    {
      store: "Store 1",
      stock: "341",
    },
    {
      store: "Store 2",
      stock: "875",
    },
    {
      store: "Store 3",
      stock: "6332",
    },
  ];

  const [selectedOption, setSelectedOption] = useState("Quantity");

  const productInfoData = [
    {
      title: "Quantity",
      imgSrc: Quantity,
    },
    {
      title: "Offers",
      imgSrc: Offers,
    },
    {
      title: "Stock",
      imgSrc: Stock,
    },
    {
      title: "Sales",
      imgSrc: Sales,
    },
    {
      title: "Info",
      imgSrc: Info,
    },
  ];

  const handleProductInfo = (record) => {
    setSelectedOption(record);
  };

  return (
    <div style={{ width: "100%" }}>
      <Row>
        <Col span={12}>
          <p style={data.posScreenStyles.quantityUpdateCard.offersHeading}>Product Details</p>
        </Col>
        <Col span={12}>
          <img src={CircleCloseIcon} alt="" style={{ float: "right" }} onClick={() => setIsQtyUpdate(false)} />
        </Col>
      </Row>
      <Row
        style={{
          marginBottom: data.posScreenStyles.quantityUpdateCard.marginBottom,
        }}
      >
        <Col {...full}>
          <Row gutter={[0, 10]}>
            <Col {...full}>
              <Card bodyStyle={{ padding: 10 }} className="productInfoCard" style={data.posScreenStyles.quantityUpdateCard.productInfoCard}>
                <Row>
                  <Col {...four}>
                    <img style={data.posScreenStyles.quantityUpdateCard.productImg} onError={setDefaultImage} src={selectedProductInCart.imageurl} alt="" />
                  </Col>
                  <Col {...twenty}>
                    <Row>
                      <Col {...full}>
                        <Row>
                          <label>
                            <span style={data.posScreenStyles.quantityUpdateCard.prodNameDetails}>{selectedProductInCart.name}</span>
                          </label>
                        </Row>
                        <Row>
                          <label style={data.posScreenStyles.quantityUpdateCard.prodPriceLbl}>
                            &#8377;&nbsp;
                            <span>{selectedProductInCart.salePrice}</span>
                          </label>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <p style={data.posScreenStyles.quantityUpdateCard.prodDescLbl} className="prod-desc-lbl">
                          {selectedProductInCart.description}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <InfoCircleFilled style={data.posScreenStyles.quantityUpdateCard.infoCircleIcon} />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        {selectedOption === "Quantity" && (
          <Col span={14} style={{ paddingRight: "1.5%" }}>
            <Card bodyStyle={{ padding: 0 }} className="prodQtyCard" style={data.posScreenStyles.quantityUpdateCard.prodQtyCard}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Input size="large" value={weight} onChange={onChangeWeight} allowClear={false} style={data.posScreenStyles.quantityUpdateCard.quantityUpdateInput} />
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1.33">
                    <button style={data.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => handleWeight("1")}>
                      1
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1.33">
                    <button style={data.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => handleWeight("2")}>
                      2
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1.33">
                    <button style={data.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => handleWeight("3")}>
                      3
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1.33">
                    <button style={data.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => setIsQtyUpdate(false)}>
                      <img src={Aclose} style={data.posScreenStyles.quantityUpdateCard.qtyBtnClose} alt="close icon" />
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1.33">
                    <button style={data.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => handleWeight("4")}>
                      4
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1.33">
                    <button style={data.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => handleWeight("5")}>
                      5
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1.33">
                    <button style={data.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => handleWeight("6")}>
                      6
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1.33">
                    <button style={data.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => handleWeight("x")}>
                      <img src={Backspace} alt="back space" style={data.posScreenStyles.quantityUpdateCard.qtyBtnBack} />
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={18}>
                  <Row gutter={[16, 16]}>
                    <Col span={8}>
                      <AspectRatio ratio="1.33">
                        <button style={data.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => handleWeight("7")}>
                          7
                        </button>
                      </AspectRatio>
                    </Col>
                    <Col span={8}>
                      <AspectRatio ratio="1.33">
                        <button style={data.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => handleWeight("8")}>
                          8
                        </button>
                      </AspectRatio>
                    </Col>
                    <Col span={8}>
                      <AspectRatio ratio="1.33">
                        <button style={data.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => handleWeight("9")}>
                          9
                        </button>
                      </AspectRatio>
                    </Col>
                    <Col span={16}>
                      <button className="quantity-btn-zero" style={data.posScreenStyles.quantityUpdateCard.quantityBtnZero} onClick={() => handleWeight("0")}>
                        0
                      </button>
                    </Col>
                    <Col span={8}>
                      <AspectRatio ratio="1.33">
                        <button style={data.posScreenStyles.quantityUpdateCard.quantityBtn} onClick={() => handleWeight(".")}>
                          .
                        </button>
                      </AspectRatio>
                    </Col>
                  </Row>
                </Col>
                <Col span={6}>
                  <Row gutter={[16, 16]} style={{ height: "100%" }}>
                    <Col span={24}>
                      <button style={data.posScreenStyles.quantityUpdateCard.quantityBtnOk} onClick={() => addQuantity()}>
                        {/* <AspectRatio ratio="1"> */}
                        <img src={ok} alt="ok" width={40} />
                        {/* </AspectRatio> */}
                      </button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        )}
        {selectedOption === "Offers" && (
          <Col span={14} style={{ paddingRight: 10 }}>
            <Card bodyStyle={{ padding: 0 }} className="offersCard" title={<span style={data.posScreenStyles.quantityUpdateCard.offersHeading}>Offers</span>}>
              <Scrollbars autoHide className="offersCardList">
                <div style={data.posScreenStyles.quantityUpdateCard.offersDiv}>
                  {offersList.map((item) => (
                    <Card bodyStyle={{ padding: 10 }} style={data.posScreenStyles.quantityUpdateCard.offersCardStyles}>
                      <Row>
                        <Col span={4}>
                          <img src={DiscountImg} alt="discount" />
                        </Col>
                        <Col span={20}>
                          <Row>
                            <h3 style={data.posScreenStyles.quantityUpdateCard.offersTitle}>{item.title}</h3>
                          </Row>
                          <Row>
                            <h3 style={data.posScreenStyles.quantityUpdateCard.offersOffer}>{item.offer}</h3>
                          </Row>
                        </Col>
                      </Row>
                    </Card>
                  ))}
                </div>
              </Scrollbars>
            </Card>
          </Col>
        )}
        {selectedOption === "Stock" && (
          <Col span={14} style={{ paddingRight: 10 }}>
            <Card bodyStyle={{ padding: 0 }} className="offersCard" title={<span style={data.posScreenStyles.quantityUpdateCard.offersHeading}>Store Stock</span>}>
              <div style={data.posScreenStyles.quantityUpdateCard.offersDiv}>
                <Card bodyStyle={{ padding: 10 }} style={data.posScreenStyles.quantityUpdateCard.stockCardStyles}>
                  <Row>
                    <Col span={20}>
                      <Row>
                        <p style={data.posScreenStyles.quantityUpdateCard.stockTitle}>Quantity</p>
                      </Row>
                      <Row>
                        <p style={data.posScreenStyles.quantityUpdateCard.stockValue}>4567</p>
                      </Row>
                    </Col>
                    <Col span={4}>
                      <div>
                        <img src={StockImg2} alt="stock" style={{ float: data.posScreenStyles.quantityUpdateCard.float }} />
                      </div>
                    </Col>
                  </Row>
                </Card>
                <Divider style={data.posScreenStyles.cartTable.divider} />
                <h3 style={data.posScreenStyles.quantityUpdateCard.nearbyStock}>Nearby Store Stocks</h3>
                <Divider style={data.posScreenStyles.cartTable.divider} />
                <Table
                  style={data.posScreenStyles.quantityUpdateCard.stockTable}
                  size="small"
                  pagination={false}
                  dataSource={stockList}
                  columns={[
                    {
                      title: "Store",
                      dataIndex: "store",
                      key: "store",
                    },
                    {
                      title: "In Stock",
                      dataIndex: "stock",
                      key: "stock",
                      align: "right",
                    },
                  ]}
                />
              </div>
            </Card>
          </Col>
        )}
        {selectedOption === "Sales" && (
          <Col span={14} style={{ paddingRight: 10 }}>
            <Card bodyStyle={{ padding: 0 }} className="offersCard" title={<span style={data.posScreenStyles.quantityUpdateCard.offersHeading}>Sales</span>}>
              <Scrollbars autoHide className="infoCardList">
                <Row gutter={[0, 12]} style={data.posScreenStyles.quantityUpdateCard.salesRow}>
                  <Col span={24}>
                    <Card bodyStyle={{ padding: 10 }} style={data.posScreenStyles.quantityUpdateCard.salesCardStyles}>
                      <p style={data.posScreenStyles.quantityUpdateCard.stockTitle}>Today's Sales</p>
                      <p style={data.posScreenStyles.quantityUpdateCard.stockValue}>$1.2M</p>
                      <Row>
                        <Col span={20}>
                          <p style={data.posScreenStyles.quantityUpdateCard.salesPercentNegValue}>- 25%</p>
                        </Col>
                        <Col span={4}>
                          <img src={DownArrowRed} alt="down arrow" style={{ float: data.posScreenStyles.quantityUpdateCard.float }} />
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card bodyStyle={{ padding: 10 }} style={data.posScreenStyles.quantityUpdateCard.salesCardStyles2}>
                      <p style={data.posScreenStyles.quantityUpdateCard.stockTitle}>Weekly Sales</p>
                      <p style={data.posScreenStyles.quantityUpdateCard.stockValue}>$48M</p>
                      <Row>
                        <Col span={20}>
                          <p style={data.posScreenStyles.quantityUpdateCard.salesPercentPosValueWeek}>+ 15%</p>
                        </Col>
                        <Col span={4}>
                          <img src={UpArrowGreen} alt="down arrow" style={{ float: data.posScreenStyles.quantityUpdateCard.float }} />
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col span={12} style={{ paddingLeft: 10 }}>
                    <Card bodyStyle={{ padding: 10 }} style={data.posScreenStyles.quantityUpdateCard.salesCardStyles2}>
                      <p style={data.posScreenStyles.quantityUpdateCard.stockTitle}>Monthly Sales</p>
                      <p style={data.posScreenStyles.quantityUpdateCard.stockValue}>$456M</p>
                      <Row>
                        <Col span={20}>
                          <p style={data.posScreenStyles.quantityUpdateCard.salesPercentNegValue}>- 25%</p>
                        </Col>
                        <Col span={4}>
                          <img src={DownArrowRed} alt="down arrow" style={{ float: data.posScreenStyles.quantityUpdateCard.float }} />
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card bodyStyle={{ padding: 10 }} style={data.posScreenStyles.quantityUpdateCard.salesCardStyles2}>
                      <p style={data.posScreenStyles.quantityUpdateCard.stockTitle}>Yearly Sales</p>
                      <p style={data.posScreenStyles.quantityUpdateCard.stockValue}>$3678M</p>
                      <Row>
                        <Col span={20}>
                          <p style={data.posScreenStyles.quantityUpdateCard.salesPercentPosValueWeek}>+ 25%</p>
                        </Col>
                        <Col span={4}>
                          <img src={DownArrowGreen} alt="down arrow" style={{ float: data.posScreenStyles.quantityUpdateCard.float }} />
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col span={12} style={{ paddingLeft: 10 }} />
                  {/* <Col span={12}>
                      <Card
                        bodyStyle={{ padding: 10 }}
                        style={data.posScreenStyles.quantityUpdateCard.salesCardStyles2}
                      >
                        <p style={data.posScreenStyles.quantityUpdateCard.stockTitle}>Yearly Sales</p>
                        <p style={data.posScreenStyles.quantityUpdateCard.stockValue}>$3678M</p>
                        <Row>
                          <Col span={20}>
                            <p style={data.posScreenStyles.quantityUpdateCard.salesPercentPosValueWeek}>+ 25%</p>
                          </Col>
                          <Col span={4}>
                            <img
                              src={DownArrowGreen}
                              alt="down arrow image"
                              style={{float: data.posScreenStyles.quantityUpdateCard.float}}
                            />
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                    <Col span={12} style={{ paddingLeft: 10 }}>
                      <Card
                        bodyStyle={{ padding: 10 }}
                        style={data.posScreenStyles.quantityUpdateCard.salesCardStyles2}
                      >
                        <p style={data.posScreenStyles.quantityUpdateCard.stockTitle}>Yearly Sales</p>
                        <p style={data.posScreenStyles.quantityUpdateCard.stockValue}>$3678M</p>
                        <Row>
                          <Col span={20}>
                            <p style={data.posScreenStyles.quantityUpdateCard.salesPercentPosValueWeek}>+ 25%</p>
                          </Col>
                          <Col span={4}>
                            <img
                              src={DownArrowGreen}
                              alt="down arrow image"
                              style={{float: data.posScreenStyles.quantityUpdateCard.float}}
                            />
                          </Col>
                        </Row>
                      </Card>
                    </Col> */}
                </Row>
              </Scrollbars>
            </Card>
          </Col>
        )}
        {selectedOption === "Info" && (
          <Col span={14} style={{ paddingRight: 10 }}>
            <Card bodyStyle={{ padding: 0 }} className="offersCard" title={<span style={data.posScreenStyles.quantityUpdateCard.offersHeading}>Product Information</span>}>
              <Scrollbars autoHide className="infoCardList">
                <div style={{ padding: 10 }}>
                  <p style={data.posScreenStyles.quantityUpdateCard.infoTitle}>Description</p>
                  <p style={data.posScreenStyles.quantityUpdateCard.infoDesc}>
                    Canning is a method of preserving food in which the food contents are processed and sealed in an airtight container (jars like Mason jars, and steel and tin
                    cans). Canning provides a shelf life typically ranging from one to five years, although under specific circumstances it can be much longer. A freeze-dried
                    canned product, such as canned dried lentils, could last as long as 30 years in an edible state. In 1974, samples of canned food from the wreck of the Bertrand,
                    a steamboat that sank in the Missouri River in 1865, were tested by the National Food Processors Association
                  </p>
                  <p style={data.posScreenStyles.quantityUpdateCard.infoTitle}>Key Features</p>
                  <p style={data.posScreenStyles.quantityUpdateCard.infoDesc}>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At
                    vero eos et accusam et
                  </p>
                  <p style={data.posScreenStyles.quantityUpdateCard.infoTitle}>Unit</p>
                  <p style={data.posScreenStyles.quantityUpdateCard.infoDesc}>450 ml</p>
                  <p style={data.posScreenStyles.quantityUpdateCard.infoTitle}>Shelf Life</p>
                  <p style={data.posScreenStyles.quantityUpdateCard.infoDesc}>3 months</p>
                </div>
              </Scrollbars>
            </Card>
          </Col>
        )}
        <Col span={10}>
          {productInfoData.map((item) => (
            <Card
              className="qty-card"
              style={item.title === selectedOption ? data.posScreenStyles.quantityUpdateCard.qtyInfoCardSelected : data.posScreenStyles.quantityUpdateCard.qtyInfoCard}
              bodyStyle={{ padding: 10 }}
              onClick={() => handleProductInfo(item.title)}
            >
              <Row style={data.posScreenStyles.quantityUpdateCard.qtyCardRow}>
                <Col {...half}>
                  <img src={item.imgSrc} alt="" className="qty-img" />
                </Col>
                <Col {...half} className="padding-ten">
                  <label className="qty-lbl">{item.title}</label>
                </Col>
              </Row>
            </Card>
          ))}
        </Col>
      </Row>
    </div>
  );
};

export default QuantityUpdate;
