import React, { useState, useEffect } from "react";
import { Card, Row, Col, Form, Input, Button, Spin, Modal } from "antd";
import { serverTokenUrl, serverUrl } from "../../constants/config";
import { LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../lib/auth";
import Axios from "axios";
import db from "../../database";
import loginBackground from "../../assets/images/backgroundImage.png";
import logoIcon from "../../assets/images/cwpos-blue.png";
import data from "../../constants/registrationLogin.json";
import "antd/dist/antd.css";
import "../style.css";

const Login = () => {
  const [till, setTill] = useState("");
  const [loading, setLoading] = useState({ status: false, message: "" });
  const { setAuthTokens } = useAuth();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const tillRegistrations = await db.tillRegistrations.toArray();
      if (tillRegistrations.length > 0) {
        setTill(tillRegistrations[0].searchKey);
      } else {
        history.push("/registration");
      }
    })();
  }, []);

  // const getDefaultCustomer = async (tillData, tokens) => {
  //   const defaultCustomerResponse = await Axios({
  //     url: serverUrl,
  //     method: "POST",
  //     data: {
  //       query: `query{
  //         customerSearch(mobileNo:"${tillData.tillAccess.csBunit.b2cCustomer.mobileNo}"){
  //           cwrCustomerId 
  //           code   
  //           name
  //           email        
  //           mobileNo
  //           name
  //           pincode
  //           retlLoyaltyBalance
  //           b2cRegisteredstoreId
  //            iscredit
  //           balancepoints
  //           loyaltyLevel{
  //               cwrLoyaltyLevelId
  //               name
  //               accumulationRate
  //               redemptionRate
  //           }
  //           sCustomer{
  //             sCustomerID
  //             customerCategory{
  //               sCustomerCateforyId
  //               value
  //               name
  //               description
  //             }
  //           }
  //           csBunit{
  //             csBunitId
  //             name
  //           }
  //         }
  //       }`,
  //     },
  //     headers: {
  //       "Content-Type": "Application/json",
  //       Authorization: `${tokens.token_type} ${tokens.access_token}`,
  //     },
  //   });
  //   return defaultCustomerResponse.data.data.customerSearch[0];
  // };

  const onLogin = async (values) => {
    setLoading({ status: true, message: "" });
    const username = values.username.trim();
    const password = values.password.trim();

    const requestBody = decodeURIComponent(new URLSearchParams({ grant_type: "password", username, password }).toString());
    let resTokenData;

    try {
      resTokenData = await Axios.post(serverTokenUrl, requestBody, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Basic dGFsazJhbWFyZXN3YXJhbjpteS1zZWNyZXQ=",
        },
      });
    } catch (error) {
      setLoading({ status: false, message: "" });
      console.error(error);
      errorMessage(resTokenData.data.error, resTokenData.data.error_description);
      return null;
    }

    try {
      const resData = await Axios({
        url: serverUrl,
        method: "POST",
        data: {
          query: `query{
            tillData(user:"${username}"){   
              tillAccess{
                cwrTillaccessId
                csClientId
                csUserId
                userAccessController
                cwrTill{
                   cwrTillID
                   searchKey
                   till
                   description
                   nextAssignedNumber
                   prefix
                   suffix
                   loyaltyProgram
                   accessController 
                   posType
                   manageCash
                   showopeningamount
                   showsalesamount
                   showexpectedamount
                   showdifferenceamount
                   shiftclose
                   shiftopen
                   eReceipt
                   printPreview
                   cashin
                   cashout
                   layAway
                   payNow
                   hardwareController{
                       imageUrl
                   }
                   printTemplate{
                    cwrPrinttemplateId
                    name
                    htmlcode
                    htmlcode2
                    xmlcode
                    xmlcode2
                  }
                  tillCloseTemplate{
                     cwrPrinttemplateId
                    name
                    htmlcode
                    htmlcode2 
                  }
                  
                }
                csBunit{
                  csBunitId
                  name
                  value
                  cwrSCustomerId
                  cwrCsDoctypeId
                  cwrPcatalogueId
                  cwrSpricelistId
                  incDayOpening
                  pCatalogueSaleType{
                    cwrPcatalogueSaletypeId          
                    isPromoApplicable
                    cwrSaletypeId
                  }
                  b2cCustomer{
                    cwrCustomerId
                    code
                    name
                    email
                    mobileNo
                    pincode
                    retlLoyaltyBalance
                    b2cRegisteredstoreId
                    iscredit
                    balancePoints
                    loyaltyLevel{
                    cwrLoyaltyLevelId
                    name
                    accumulationRate
                    redemptionRate
                  }
              
                sCustomer{
                sCustomerID
                customerCategory{
                  sCustomerCateforyId
                  value
                  name
                  description
              }
                }
                  }
                  paymentMethodList{
                    cWRPaymentMethodID
                    finPaymentmethodId
                    finFinancialAccountId
                    name
                    integratedPayment
                    isloyalty
                    paymentProvider
                    iscredit 
                    isGiftCard
                  }
                  mWarehouse{
                    mWarehouseID
                    name
                  }
                  customerAddress{
                    sCustomerAddressID
                      line1
                      line2
                      line3
                      fulladdress
                      phone
                      city
                      postalcode
                      csCountry{
                          csCountryID
                          name          
                      }
                      csRegion{
                          csRegionID
                          name
                      }
                  }
                  locations{
                      csBunitLocationId
                      fulladdress
                      phone
                      contactPerson
                  }
                   salesRep{
                      code
                      name
                      salesRepresentId
                  }
                }
                
              }
              status
              message
            }
          
          }`,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `${resTokenData.data.token_type} ${resTokenData.data.access_token}`,
        },
      });

      let tillData = resData.data.data.tillData;
      if (tillData.status === "200") {
        if (tillData !== null && tillData !== undefined) {
          const tillAccessIndex = tillData.tillAccess.findIndex((accessTill) => accessTill.cwrTill.till === till);
          if (tillAccessIndex >= 0) {
            tillData.tillAccess = tillData.tillAccess[tillAccessIndex];
            // const defaultCustomer = await getDefaultCustomer(tillData, resTokenData.data);
            localStorage.setItem("username", username);
            localStorage.setItem("tillData", JSON.stringify(tillData));
            // localStorage.setItem("defaultCustomer", JSON.stringify(defaultCustomer));
            setAuthTokens(resTokenData.data);
            setLoading({ status: false, message: "" });
            const productCategories = await db.productCategories.toArray();
            if (productCategories.length > 0) {
              history.push("/dashboard");
            } else {
              history.push("/sync");
            }
          } else {
            setLoading({ status: false, message: "" });
            errorMessage("Login Failed", "User not configured for this Till");
          }
        } else {
          setLoading({ status: false, message: "" });
          errorMessage("Login Failed", "No Till Access Found");
        }
      } else {
        setLoading({ status: false, message: "" });
        errorMessage("Login Failed", "Till Access Failed");
      }
    } catch (error) {
      setLoading({ status: false, message: "" });
      errorMessage("Login Failed", "Invalid Username or Password");
      console.error(error);
    }
  };

  const errorMessage = (modalTitle, modalContent) => {
    Modal.error({
      title: modalTitle,
      content: modalContent,
    });
  };

  const sectionStyle = {
    width: data.loginStyles.sectionStyle.width,
    height: data.loginStyles.sectionStyle.height,
    paddingTop: data.loginStyles.sectionStyle.paddingTop,
    backgroundImage: `url(${loginBackground})`,
    backgroundRepeat: data.loginStyles.sectionStyle.backgroundRepeat,
    backgroundSize: data.loginStyles.sectionStyle.backgroundRepeat,
  };

  const antIcon = <LoadingOutlined style={{ fontSize: data.loginStyles.fontSize }} spin />;

  return (
    <div className="aspectRatio">
      <div style={sectionStyle}>
        <Card style={data.loginStyles.loginCard}>
          <Spin indicator={antIcon} spinning={loading.status} tip={loading.message}>
            <div style={data.loginStyles.loginLogoDiv}>
              <img style={data.loginStyles.loginLogo} src={logoIcon} alt="" />
            </div>
            <Form onFinish={onLogin}>
              <Form.Item>
                <Row>
                  <Col span={8}>
                    <label style={data.loginStyles.loginLabel}>Till ID</label>
                  </Col>
                  <Col span={16}>
                    <label style={data.loginStyles.loginLabel}>{till}</label>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item name="username" rules={[{ required: true, message: "Please input your Username!" }]}>
                <Row gutter="5">
                  <Col span={8}>
                    <label>Username</label>
                  </Col>
                  <Col span={16}>
                    <Input style={data.loginStyles.loginInput} placeholder="Username" />
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item name="password" rules={[{ required: true, message: "Please input your password!" }]}>
                <Row gutter="5">
                  <Col span={8}>
                    <label>Password</label>
                  </Col>
                  <Col span={16}>
                    <Input.Password style={data.loginStyles.loginInput} placeholder="Password" />
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item>
                <br />
                <Row gutter={[5, 0]}>
                  <Col span={12} />
                  <Col span={12}>
                    <Button size="large" style={data.loginStyles.btn} htmlType="submit">
                      Login
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Spin>
        </Card>
        <br />
        <div style={data.loginStyles.copyrightMainDiv}>
          <label style={data.loginStyles.copyright}>&emsp;&emsp;Copyright © {new Date().getFullYear()} CW Solutions Pvt Ltd | All rights reserved</label>
          <br />
          <label style={data.loginStyles.copyright}>&emsp;&emsp;www.cw.solutions</label>
        </div>
      </div>
    </div>
  );
};

export default Login;
